import React from 'react';

import MoreIcon from '@atlaskit/icon/glyph/more';

import { BreadcrumbsEllipsisButton } from './BreadcrumbsAncestorsDropdownButton';

export const BreadcrumbsAncestorsDropdownButtonPlaceholder = () => {
	return (
		<BreadcrumbsEllipsisButton
			isSelected={false}
			appearance="subtle"
			iconBefore={<MoreIcon label="More" />}
		/>
	);
};
