import React, { useState } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import Popup from '@atlaskit/popup';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { token } from '@atlaskit/tokens';
import { N0, N30, N500 } from '@atlaskit/theme/colors';

import { LoadableAfterPaint } from '@confluence/loadable';

import { BreadcrumbsContentMenu } from './BreadcrumbsContentMenu';

export const i18n = defineMessages({
	moreBreadcrumbsLabel: {
		id: 'breadcrumbs.ancestors.dropdown.button.label',
		defaultMessage: 'More breadcrumbs',
		description: 'Label for button that shows all ancestor breadcrumbs for a page',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IndentIconPlaceholder = styled.div({
	width: '24px',
	height: '24px',
});

export const IndentIcon = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-IndentIcon" */ './img/IndentIcon')).IndentIcon,
	loading: () => <IndentIconPlaceholder />,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BreadcrumbsEllipsisButton = styled(Button)<{
	isSelected: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	width: '22px !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	height: '20px !important',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (p) =>
		p.isSelected ? token('color.text.selected', N30) : token('color.text.selected', N0),
	color: token('color.text', N500),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		height: '13px',
		position: 'absolute',
		bottom: '0px',
		width: '20px',
	},
});

export const BreadcrumbsAncestorsDropdownButton = ({
	ancestors,
	handleClickAncestorBreadcrumb,
}) => {
	const intl = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const handleClickAncestorDropdownItem = (ancestor, i) => {
		handleClickAncestorBreadcrumb(ancestor.title, i);
		setIsOpen(false);
	};

	const contents = ancestors.map(({ id, title, href, contentType }) => ({
		id,
		type: contentType,
		title,
		href,
	}));

	const popupContent = () => {
		return (
			<BreadcrumbsContentMenu
				contents={contents}
				showIndentIcon
				onLinkItemClick={handleClickAncestorDropdownItem}
			/>
		);
	};

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="bottom-start"
			content={popupContent}
			shouldUseCaptureOnOutsideClick
			trigger={(triggerProps) => (
				<BreadcrumbsEllipsisButton
					{...triggerProps}
					appearance="subtle"
					isSelected={isOpen}
					onClick={() => setIsOpen(!isOpen)}
					iconBefore={<MoreIcon label={intl.formatMessage(i18n.moreBreadcrumbsLabel)} />}
				/>
			)}
		/>
	);
};
