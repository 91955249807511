import { useCallback } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';

import { useSessionData } from '@confluence/session-data';

import features from './whiteboard-feature-flags.json';
import featureGates from './whiteboard-feature-gates.json';

export type WhiteboardFeatures = keyof typeof features | keyof typeof featureGates;

// These flags are no longer used in confluence and can be removed from this array entirely after we confirm that have been removed from the whiteboards side too
const ARCHIVED_FEATURE_FLAGS = [
	'headerV1',
	'headerV2',
	'whiteboardArchive',
	'removeWhiteboardsHome',
	'removeWhiteboardsHomeInSideNav',
	'changeboardingBanner',
	'whiteboardCreation',
] as const;
type ArchivedFeatureFlags = (typeof ARCHIVED_FEATURE_FLAGS)[number];
type ActiveFeatureFlags = keyof typeof features;
type ActiveFeatureGates = keyof typeof featureGates;

export const useIsWhiteboardFeatureEnabled = () => {
	const { featureFlagClient } = useSessionData();

	const isWhiteboardFeatureEnabled = useCallback(
		(featureName: ActiveFeatureFlags | ArchivedFeatureFlags | ActiveFeatureGates) => {
			// If the FF has been cleaned up on our end just return true
			if (ARCHIVED_FEATURE_FLAGS.some((feature) => feature === featureName)) {
				return true;
			}

			if (featureName in featureGates) {
				const featureGate = featureGates[featureName as ActiveFeatureGates];
				return FeatureGates.checkGate(featureGate);
			}

			return (
				featureName in features &&
				featureFlagClient.getBooleanValue(features[featureName as ActiveFeatureFlags], {
					default: false,
					shouldTrackExposureEvent: true,
				})
			);
		},
		[featureFlagClient],
	);

	const isWhiteboardCreationDropdownEnabled = useCallback((): boolean => {
		return (
			isWhiteboardFeatureEnabled('whiteboardsEnabled') &&
			!isWhiteboardFeatureEnabled('whiteboardOptOutPhase1')
		);
	}, [isWhiteboardFeatureEnabled]);

	const isWhiteboardGAOnboardingEnabled = useCallback(() => {
		return (
			isWhiteboardFeatureEnabled('gaOnboarding') &&
			isWhiteboardFeatureEnabled('whiteboardsEnabled') &&
			!isWhiteboardFeatureEnabled('whiteboardOptOutPhase1')
		);
	}, [isWhiteboardFeatureEnabled]);

	return {
		platformFeatureFlagResolver: getBooleanFF,
		isWhiteboardFeatureEnabled,
		isWhiteboardCreationDropdownEnabled,
		isWhiteboardGAOnboardingEnabled,
	};
};
